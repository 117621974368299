import $request from "./_request";

async function getRewards() {
    const res = await $request("/rewards");
    return res.json();
}

export default {
    getRewards,
};
