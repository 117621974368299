import $auth from "./_auth";

import analytics from "./analytics";
import articles from "./articles";
import auth from "./auth";
import billing from "./billing";
import collections from "./collections";
import etc from "./etc";
import rewards from "./rewards";
import user from "./user";

export { APIError } from "./_request";

export const API = {
    $auth,
    analytics,
    articles,
    auth,
    billing,
    collections,
    etc,
    rewards,
    user,
};
